//================================== GLOBAL ========================================
p {
  margin: 0;
}

.scroll-visibility {
  --scroll-display: none;
  ::-webkit-scrollbar {
    opacity: 0;
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    display: var(--scroll-display);
    background-color: grey;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}
.prevent-inheritance > * {
  --scroll-display: none;
}

//================================== MIXINS ========================================

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//================================== KEYFRAMES ========================================

@include keyframes(od-rotating) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@include keyframes(od-jump) {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

@include keyframes(od-pulse) {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@include keyframes(od-fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(od-fadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@include keyframes(od-zoomIn) {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@include keyframes(od-zoomOut) {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@include keyframes(od-slideDown) {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(od-slideUp) {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(od-slideRight) {
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(od-slideFromBottom) {
  from {
    opacity: 0;
    transform: translateY(240px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//================================== CLASS NAMES ========================================

.od-hoverableWrapper {
  .od-wrapperHoverHidden {
    display: none;
  }
  &:hover {
    .od-wrapperHoverHidden {
      display: block;
    }
  }
  .od-wrapperHoverVisible {
    display: inherit;
  }
  &:hover {
    .od-wrapperHoverVisible {
      display: none;
    }
  }
}

.od-animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.od-rotating {
  @include animation(od-rotating 1s linear infinite);
}

.od-jump {
  @include animation((od-jump 1.5s infinite));
}

.od-pulse {
  @include animation((od-pulse 1.5s infinite));
}

.od-fadeIn {
  @include animation(od-fadeIn 500ms);
}

.od-fadeOut {
  @include animation(od-fadeOut 500ms);
}

.od-zoomIn {
  @include animation(od-zoomIn 500ms);
}

.od-zoomOut {
  @include animation(od-zoomOut 500ms);
}

.od-zoomInOut {
  @include animation(od-zoomInOut 1200ms);
}

.od-slideDown {
  @include animation(od-slideDown 500ms)
}

.od-slideUp {
  @include animation(od-slideUp 500ms)
}

.od-slideRight {
  @include animation(od-slideRight 500ms)
}

.od-slideFromBottom {
  @include animation(od-slideFromBottom 500ms)
}

.od-EditorWrapper {
  .ql-container {
    font-size: 14px !important;
    border: none !important;

    .ql-editor {
      padding: 0 !important;
      max-height: 125px;
    }

    .ql-blank {
      &::before {
        left: 0 !important;
        right: 0;
        color: #b3b3b3 !important;
      }
    }

    .ql-tooltip {
      z-index: 1;
    }
  }

  .ql-toolbar {
    border: none !important;
    padding: 0px !important;

    button {
      width: 24px !important;
      padding: 2px !important;
    }
  }

  .ql-snow {
    a {
      color: #5a51de !important;
    }

    .ql-stroke {
      stroke: #b3b3b3 !important;
    }

    .ql-fill {
      fill: #b3b3b3 !important;
    }

    .ql-picker-options {
      margin-bottom: 5px;
      bottom: 100%;
      top: unset !important;
    }
  }

  .ql-active {
    background-color: #eff0f4 !important;
    font-weight: bold !important;
    color: #b3b3b3 !important
  }

  .ql-emoji {
    color: #b3b3b3 !important;
  }
}

.od-triangle {
  &,
  &::before,
  &::after {
    width: 100%;
    height: 100%;
  }

  position: relative;
  overflow: hidden;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
  pointer-events: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    // background: $background;
    pointer-events: auto;
  }

  &::before {
    // prettier-ignore
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
  }

  &::after {
    // prettier-ignore
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
  }
}

.od-triangle-border {
  border-radius: 20%;

  &::before {
    border-radius: 20% 20% 20% 55%;
  }

  &::after {
    border-radius: 20% 20% 55% 20%;
  }
}

@media only screen and (max-width: 768px) {
  .od-EditorWrapper .ql-container {
    font-size: 16px !important;
  }
}

.is-highlightBadge:before {
  -webkit-animation: badgeHighlightShimmer 3.5s;
  animation: badgeHighlightShimmer 3.5s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  background: linear-gradient(180deg,rgba(127,127,127,0) 0,rgba(127,127,127,.18) 25%,rgba(127,127,127,.3) 50%,rgba(127,127,127,.18) 75%,rgba(127,127,127,0));
  content: "";
  display: block;
  height: 90px;
  left: -1000px;
  position: absolute;
  top: -150px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 2000px;
}

@-webkit-keyframes badgeHighlightShimmer {
  0% {
    left: -1100px;
    top: -150px
  }

  50% {
    left: 100%;
    top: 550px
  }

  to {
    left: 100%;
    top: 150px
  }
}

@keyframes badgeHighlightShimmer {
  0% {
    left: -1100px;
    top: -150px
  }

  50% {
    left: 100%;
    top: 550px
  }

  to {
    left: 100%;
    top: 150px
  }
}

.warning-banner-loop-container,.warning-banner-loop-container > div,.warning-banner-loop-container > div > div{
  width: 100% !important;
}

.gradient-typography{
  color: var(#0575B5, #0575B5);
}

@keyframes gridBgAnimation {
  0% {
    object-position: 0 0;
  }

  50% {
    object-position: 0px -1000px;
  }

  to {
    object-position: 0 0;
  }
}

@keyframes zoomingInLogo {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.od-gridBgAnimation {
  @include animation(gridBgAnimation 30s infinite);
}

.od-zoomRotateLogo {
  animation-name: od-rotating, zoomingInLogo;
  animation-duration: 12s, 1s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, ease-in;
  animation-composition: add;
}

.od-slowRotate {
  animation: od-rotating 60s infinite;
}

@keyframes typingEffect {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.od-typingEffect {
  overflow: hidden;
  white-space: nowrap;
  animation: typingEffect 1s steps(40);
}

@keyframes circleAnimation {
  from {
    height: "0"
  }
  to {
    height: "100"
  }
}

.od-circleAnimation {
  animation: circleAnimation 1s;
}

@keyframes animatedSemiCircleClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animatedSemiCircleAntiClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.od-animatedSemiCircleClockwise {
  animation: animatedSemiCircleClockwise 15s linear infinite;
}

.od-animatedSemiCircleAntiClockwise {
  animation: animatedSemiCircleAntiClockwise 15s linear infinite;
}